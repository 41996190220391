import React from "react"
import Footer from "../components/Footer/Footer"
import ContentV6 from "../components/ContentV/ContentV6/ContentV6"
import ContentV6r from "../components/ContentV/ContentV6/ContentV6r"
import Navbar from "../components/Navbar/Navbar"
import Header from "../components/Headers/Header"

import Background from "../images/visionBackground.png"
import BackgroundMobile from "../images/visionBackgroundM.png"

const VisionPage = () => {
  return (
    <div>
      <Navbar />
      <Header
        title="vision"
        background={Background}
        backgroundMobile={BackgroundMobile}
      />
      <article className="bg-white w-[100%] pt-8 lg:pt-0 h-auto text-IntermetaDarkPurple">
        <ContentV6
          Title="how we see the metaverse"
          Content="Imagine taking your sword from a fantasy RPG to a multiplayer rogue lite game with your cross-metaverse avatar. Or attending a virtual car show, seeing vehicles from all sorts of different games and creators. Maybe even participating in a massive space battle with ships from famous movies across the entire sci-fi genre. All of this is only possible with intermetability, and we want to make those experiences possible. 
          "
          IMGSrc="Img"
          ButtonText="Contact Us"
          Shifted={false}
        />
        <ContentV6r
          Title="we believe in ownership"
          Content="Our vision for the metaverse does not have a bunch of siloed games where users have to buy-in. Instead, people freely travel wherever they want, taking their hard-earned items with them. This disrupts traditional business models, and we’re here for it."
          IMGSrc="Img"
          ButtonText="Contact Us"
          Shifted={false}
        />
      </article>
      <Footer />
    </div>
  )
}

export default VisionPage
