import React from "react"

interface V6Props {
  Title: string
  Content: string
  IMGSrc: string
  ButtonText: string
  Shifted?: boolean
}

const V6 = ({
  Title,
  Content,
  IMGSrc,
  ButtonText,
  Shifted = true,
}: V6Props) => {
  return (
    <div>
      <div className="flex justify-center h-auto lg:my-2 pt-10 xl:pt-0 lg:pb-20 lg:mb-0 mb-2 ">
        <div className="flex lg:flex-row-reverse flex-col-reverse lg:justify-between  justify-center items-center w-[90%] lg:w-[70%]">
          <div
            className={`relative ${
              Shifted && "right-24"
            } lg:w-[70%] w-[100%] flex lg:flex-row flex-col justify-center items-center aspect-[5/4]`}
          >
            <div className="w-[80%] aspect-[5/4] flex flex-col justify-center">
              <div className="font-bold lg:text-3xl text-2xl lg:mb-6 lg:pt-0 pt-2">
                {Title}
              </div>
              <div className="mt-2 text-bold text-md lg:ml-0 leading-relaxed lg:mb-6 font-light">
                {Content}
              </div>
              <div className="border-2 border-current lg:py-2 lg:mt-2 mt-4 lg:mb-0 mb-20 lg:p-0 p-2 aspect-[6/1] lg:w-[40%] w-[90%] flex items-center justify-center lg:text-md text-lg cursor-pointer">
                {ButtonText}
              </div>
            </div>
          </div>
          <div className="lg:w-[50%] lg:h-96 w-[85%] bg-blue-400 aspect-[5/4] rounded-tr-[3rem] rounded-bl-[3rem]">
            {IMGSrc}
          </div>
        </div>
      </div>
    </div>
  )
}

export default V6
