import React from "react"
import useMediaQuery from "../../hooks/useMediaQuery"

interface HeaderProps {
  title: string
  background: string
  backgroundMobile: string
}

const Header = ({ title, background, backgroundMobile }: HeaderProps) => {
  const isDesktop = useMediaQuery("(min-width: 1400px)")

  return (
    <div>
      <div className="h-[80vh] md:h-[60vh] flex flex-col items-center">
        {isDesktop ? (
          <img
            className="absolute h-screen lg:h-[60vh] top-0 -z-20 w-full scale-120 object-cover"
            src={background}
          />
        ) : (
          <img
            className="absolute h-screen top-0 -z-20 w-full scale-120 object-cover"
            src={backgroundMobile}
          />
        )}

        <h1 className="text-white text-5xl lg:text-6xl font-bold text-center tracking-wide pb-20 mt-64 mx-8">
          {title}
        </h1>
      </div>
    </div>
  )
}

export default Header
